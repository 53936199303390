import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

apiClient.interceptors.request.use(config => {
  if (apiClient.defaults.headers.common.Authorization !== axios.defaults.headers.common.Authorization) {
    apiClient.defaults.headers.common.Authorization = axios.defaults.headers.common.Authorization;
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = axios.defaults.headers.common.Authorization;
  return config;
}, error => Promise.reject(error));

export default {
  getOrders(query) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/orders', {
        params: query,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  getOrder(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/orders/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  updateOrder(id, body) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/orders/${id}/update`, body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  setAsDownloaded(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/orders/${id}/set-as-downloaded`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  addOrder(body) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/orders', body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  deleteOrder(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/orders/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  anionimizeSubject(orderId) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/orders/${orderId}/subject`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  attachEmployee(orderId, form) {
    const result = new Promise((resolve, reject) => {
      apiClient.post(`/orders/${orderId}/employee`, form)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  detachEmployee(orderId, employeeId, taskId) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/orders/${orderId}/employee/${employeeId}/${taskId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  getLogs(orderId) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/orders/${orderId}/logs`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getOrderPostalCodes(query) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/order-subject-postal-codes', {
        params: query,
      })
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
    return result;
  },
};
