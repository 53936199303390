<template>
  <div>
    <div class="flex items-center mt-2">
      <div class="relative flex items-stretch mr-4 border border-gray-500 hover:border-gray-700 rounded-sm shadow" :class="{'bg-yellow-200': contractor_id !== null}">
        <select
          v-model="contractor_id"
          class="appearance-none w-full bg-transparent px-4 py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option class="appearance-none bg-gray-100" :value="null">TU (wszystkie)</option>
          <option
            v-for="contractor in contractors"
            :key="contractor.id"
            :value="contractor.id"
            class="appearance-none bg-gray-100"
          >
            {{ contractor.shortname }}
          </option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
      <div
        class="relative mr-4 w-auto flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm"
        :class="{'bg-yellow-200': order_close_date_to !== ''}"
      >
        <button
          class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white font-semibold py-1 px-2 cursor-default"
          type="button"
        >
          data zamknięcia zlecenia od
        </button>
        <input
          v-model="order_close_date_from"
          type="date"
          class="appearance-none text-gray-700 bg-transparent px-2 py-1 pr-8 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div
        class="relative mr-4 w-auto flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm"
        :class="{'bg-yellow-200': order_close_date_to !== ''}"
      >
        <button
          class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white font-semibold py-1 px-2 cursor-default"
          type="button"
        >
          data zamknięcia zlecenia do
        </button>
        <input
          v-model="order_close_date_to"
          type="date"
          class="appearance-none text-gray-700 bg-transparent px-2 py-1 pr-8 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <button
        @click="setFilter"
        class="mr-4 bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-3 rounded-sm"
        type="button"
        :disabled="!contractor_id"
      >Generuj raport</button>
      <button
        @click="exportCsv"
        class="bg-teal-500 hover:bg-teal-700 text-white font-semibold py-2 px-3 rounded-sm"
        type="button"
        :disabled="orders.length === 0"
      >Export CSV</button>
    </div>
    <div v-if="isBusy">
      <div class="flex w-full py-3 px-5">
        <div class="flex items-center text-center w-full">
          <font-awesome-icon icon="spinner" size="2x" spin class="mr-3" />
          Ładuję...
        </div>
      </div>
    </div>
    <template v-else>
      <!-- Podsumowanie -->
      <div class="list-group">
        <div class="flex flex-wrap w-full py-1 px-5 border-gray-400 font-semibold">
          <div class="w-1/2 lg:w-1/4">
            Ilość zleceń: {{ orders.length }}
          </div>
          <div class="w-1/2 lg:w-1/4 text-right">
            suma kosztów: {{salaryAmountSum | moneyPL}}
          </div>
          <div class="w-1/2 lg:w-1/4 text-right">
            suma przychodu: {{ordersIncomeSum | moneyPL}}
          </div>
          <div class="w-1/2 lg:w-1/4 text-right">
            dochód brutto: {{ordersIncomeSum - salaryAmountSum | moneyPL}}
          </div>
        </div>
      </div>
      <div class="list-group">
        <div class="hidden md:flex w-full py-1 px-5 border-b border-gray-400 font-semibold">
          <div class="w-full sm:w-1/2 lg:w-1/12">
            nr zlecenia
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/4">
            nr szkody
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12">
            data zlec.
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12">
            data ogl.
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12">
            data zamkn.
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/6">
            typ zlecenia
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right">
            koszt
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right">
            przychód
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right">
            dochód
          </div>
        </div>
        <div
          v-for="order in orders"
          :key="order.id"
          class="flex flex-wrap w-full py-1 px-5 border-b border-gray-400 last:border-b-0"
          :class="{'bg-green-200': order.settlements_checked == 1}"
        >
          <div class="w-full sm:w-1/2 lg:w-1/12">
            <router-link :to="{name: 'order', params: {id: order.id}}" target="_blank">
              {{order.signature}}/{{order.order_date.substring(0, 4)}}
            </router-link>
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/4">
            {{ order.claim_no }}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12">
            {{ order.order_date.substring(0, 10) }}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12">
            {{ order.inspection_date ? order.inspection_date.substring(0, 10) : 'brak' }}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12">
            {{ order.close_date.substring(0, 10) }}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/6">
            {{order.type_name}}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right">
            {{order.salary_sum | moneyPL}}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right">
            {{order.income | moneyPL}}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right font-mono">
            {{ order.income - order.salary_sum | moneyPL }}
            <font-awesome-icon v-if="checkedBusy == order.id" icon="spinner" spin />
            <font-awesome-icon
              v-else-if="order.settlements_checked == 1"
              @click="toggleSettlementsChecked(order, 0)"
              class="text-blue-600 hover:text-red-600 cursor-pointer"
              :icon="['far', 'check-square']"
            />
            <font-awesome-icon
              v-else-if="order.settlements_checked == 0"
              @click="toggleSettlementsChecked(order, 1)"
              class="text-blue-600 hover:text-blue-400 cursor-pointer"
              :icon="['far', 'square']" title="oznacz jako sprawdzone"
            />
          </div>
          <div v-show="order.comments !== null && order.comments !== ''" class="w-full text-gray-600">
            uwagi: <em class="text-gray-500">{{ order.comments }}</em>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import { errorHandler } from '../mixins/errorHandler';
import SalaryService from '../services/SalaryService';
import OrderService from '../services/OrderService';

export default {
  name: 'OrderIncomeReport',
  mixins: [errorHandler],
  data() {
    return {
      isBusy: false,
      checkedBusy: {},
      exportingCsv: false,
      query: {},
      contractor_id: null,
      order_close_date_from: '',
      order_close_date_to: '',
      orders: [],
      errors: {},
    };
  },
  computed: {
    ...mapState('orders', {
      // orderStatuses: state => state.statuses,
      // orderTypes: state => state.types,
      contractors: state => state.contractors,
    }),
    salaryAmountSum() {
      return this.orders.reduce((prev, next) => ({ salary_sum: Number(prev.salary_sum) + Number(next.salary_sum) }), { salary_sum: 0 }).salary_sum;
    },
    ordersIncomeSum() {
      return this.orders.reduce((prev, next) => ({ income: Number(prev.income) + Number(next.income) }), { income: 0 }).income;
    },
  },
  mounted() {
    this.setQueryFromUri();
    if (this.contractors.length === 0) {
      this.$store.dispatch('orders/getContractors');
    }
  },
  methods: {
    setQueryFromUri() {
      let changed = 0;
      if (this.$route.query.contractor_id !== undefined) {
        this.query.contractor_id = this.$route.query.contractor_id;
        this.contractor_id = this.query.contractor_id;
      }
      if (this.$route.query.order_close_date_to !== undefined) {
        this.query.order_close_date_to = this.$route.query.order_close_date_to;
      } else {
        this.query.order_close_date_to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        changed += 1;
      }
      this.order_close_date_to = this.query.order_close_date_to;
      if (this.$route.query.order_close_date_from !== undefined) {
        this.query.order_close_date_from = this.$route.query.order_close_date_from;
      } else {
        this.query.order_close_date_from = moment(this.order_close_date_to).startOf('month').format('YYYY-MM-DD');
        changed += 1;
      }
      this.order_close_date_from = this.query.order_close_date_from;

      if (changed > 0) {
        const { name, params } = this.$route;
        this.$router.replace({ name, params, query: this.query });
      } else if (this.contractor_id && this.order_close_date_from !== '' && this.order_close_date_to !== '') {
        this.getOrdersSettlement();
      }
    },
    getOrdersSettlement() {
      this.isBusy = true;
      const query = {};
      Object.keys(this.query).forEach(key => {
        query[key] = decodeURI(this.$route.query[key]);
      });
      if (!this.contractor_id) {
        this.errors.contractor_id = 'Pole obowiązkowe';
      }
      SalaryService.getOrdersSettlement(this.query.contractor_id, query)
        .then(response => {
          this.orders = response.data.result;
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
    toggleSettlementsChecked(order, value) {
      this.checkedBusy = order.id;
      OrderService.updateOrder(order.id, { settlements_checked: value })
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          order.settlements_checked = value;
          this.checkedBusy = null;
        })
        .catch(error => {
          this.resolveError(error);
          this.checkedBusy = null;
        });
    },
    exportCsv() {
      const query = {
        order_close_date_from: this.query.order_close_date_from,
        order_close_date_to: this.query.order_close_date_to,
        export_csv: 1,
      };
      this.exportingCsv = true;
      SalaryService.getOrdersSettlement(this.query.contractor_id, query)
        .then(response => {
          this.exportingCsv = false;
          const newWindow = window.open(null, 'downloadCSV');
          newWindow.location.href = response.data.url;
        })
        .catch(error => {
          this.resolveError(error);
          this.exportingCsv = false;
        });
      return true;
    },
    setFilter() {
      Object.assign(this.query, this.$route.query);
      delete this.query.contractor_id;
      delete this.query.order_close_date_from;
      delete this.query.order_close_date_to;
      if (this.contractor_id !== '') {
        this.query.contractor_id = this.contractor_id;
      }
      if (this.order_close_date_from !== '') {
        this.query.order_close_date_from = this.order_close_date_from;
      }
      if (this.order_close_date_to !== '') {
        this.query.order_close_date_to = this.order_close_date_to;
      }
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.query)) {
        this.$router.push({ name: 'orderIncomeReport', query: this.query });
      }
      this.getOrdersSettlement();
    },
  },
};
</script>

<style scoped lang="scss">
  .list-group {
    @apply mt-2;
    @apply border;
    @apply rounded-sm;
    @apply border-gray-400;
  }
  .tab {
    @apply bg-white;
    @apply inline-block;
    @apply h-full;
    @apply py-2;
    @apply px-4;
    @apply font-semibold;
    &.active {
      @apply text-gray-700;
      @apply cursor-default;
      @apply border-l;
      @apply border-t;
      @apply border-r;
      @apply rounded-t;
      &:hover {
        @apply text-gray-700;
        @apply no-underline;
      }
    }
    &:not(.active) {
      @apply cursor-pointer;
      @apply text-blue-500;
      &:hover {
        @apply text-blue-800;
      }
    }
  }
</style>
